import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { HreflangLanguage } from "./enums/hreflang.language.enum";

@Injectable({
  providedIn: "root"
})
export class MetaDataService {
  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private meta: Meta,
    private router: Router
  ) {}

  public setDocumentTitle(title: string): void {
    this.title.setTitle(title);
  }

  public addOrUpdateTag(name: string, content: string): HTMLMetaElement | null {
    if (this.meta.getTag("name=" + name)) {
      return this.meta.updateTag(
        {
          name: name,
          content: content
        },
        "name=" + name
      );
    }
    return this.meta.addTag({
      name: name,
      content: content
    });
  }

  public syncAlternateLinkWithNavigation(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.router.events.pipe(filter((val: any) => val instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.setHreflangLink(this.buildUrl(event), HreflangLanguage.GERMAN_GERMANY);
      this.clearHreflangLink(HreflangLanguage.GERMAN_AUSTRIA);
      this.clearHreflangLink(HreflangLanguage.GERMAN_SWITZERLAND);
    });
  }

  public clearHreflangLink(hreflangAttribute: string) {
    this.setHreflangLink("", hreflangAttribute);
  }

  public setHreflangLink(hreflangUrlString: string, hreflangAttribute: string) {
    if (!hreflangAttribute) return;

    const currentLinkElm = this.document.head.querySelector(`link[hreflang="${hreflangAttribute}"]`);

    if (!hreflangUrlString && currentLinkElm) {
      this.document.head.removeChild(currentLinkElm);
    } else if (hreflangUrlString) {
      const linkElm = currentLinkElm ?? this.document.createElement("link");
      linkElm.setAttribute("rel", "alternate");
      linkElm.setAttribute("hreflang", hreflangAttribute);
      linkElm.setAttribute("href", hreflangUrlString);

      if (!currentLinkElm) {
        // It is recommended, to have the hreflang links very far on top of the header => pos="afterbegin"
        this.document.head.insertAdjacentElement("afterbegin", linkElm);
      }
    }
  }

  private buildUrl(navigationEvent: NavigationEnd): string {
    return "https://" + this.document.location.hostname + navigationEvent.urlAfterRedirects;
  }
}
